module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    BASE_URL: "https://jagruti-erp-server-tn7s7.ondigitalocean.app", //"https://jagruti-erp-server-tn7s7.ondigitalocean.app",
    API_URL: "https://jagruti-erp-server-tn7s7.ondigitalocean.app/api/v1", //https://jagruti-erp-server-tn7s7.ondigitalocean.app/ //http://sw.jagrutirehab.org/api/v1 //"http://localhost:8080/api/v1"
  },
};
